import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MyDataHelps from "@careevolution/mydatahelps-js";
import { LoadingIndicator, Calendar, DayTrackerSymbol } from '@careevolution/mydatahelps-ui';
import { add, isAfter, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';

import styles from './PhysicalActivityCalendar.module.scss';

const PhysicalActivityCalendar = (props) => {
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState({});

  var monthStart = new Date(props.year, props.month, 1, 0, 0, 0, 0);
  var monthEnd = add(monthStart, { months: 1 });

  async function getAllAppleWorkouts() {
    let results = [];
    let pageId = null;
    do {
      try {
        let response = await MyDataHelps.queryAppleHealthWorkouts({
          startDate: add(monthStart, { days: -3 }),
          endDate: add(monthEnd, { days: 1 }),
          pageID: pageId
        });
        results.push(...response.results);
        pageId = response.nextPageID;
      }
      catch (err) {
        break;
      }
    } while (pageId);
    return results;
  }

  async function getAllAppleSummaries() {
    let results = [];
    let pageId = null;
    do {
      try {
        let response = await MyDataHelps.queryAppleHealthActivitySummaries({
          startDate: add(monthStart, { days: -3 }),
          endDate: add(monthEnd, { days: 1 }),
          pageID: pageId
        });
        results.push(...response.results);
        pageId = response.nextPageID;
      }
      catch (err) {
        break;
      }
    } while (pageId);
    return results;
  }

  function initialize() {
    setLoading(true);
    Promise.all([getAllAppleWorkouts(), getAllAppleSummaries()]).then((results) => {
      setLoading(false);
      setActivity({ workouts: results[0], summaries: results[1] });
    });
  }

  function renderDay(year, month, day) {

    if (!day) return <div></div>;

    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let currentDate = new Date(year, month, day);

    let exerciseMinutes = 0;
    let currentSummary = null;
    if (activity && activity.summaries && activity.summaries.length) {
      currentSummary = activity.summaries.find(s => {
        let date = parseISO(s.startDate);
        let offset = s.startDate.substring(19);
        return formatInTimeZone(date, offset, "yyyy-M-d") === `${year}-${month + 1}-${day}`;
      });
    }

    if (currentSummary) {
      exerciseMinutes = currentSummary.appleExerciseTime;
    }

    let currentWorkouts = [];
    if (activity && activity.workouts && activity.workouts.length) {
      currentWorkouts = activity.workouts.filter(w => {
        let date = parseISO(w.startDate);
        let offset = w.startDate.substring(19);
        return formatInTimeZone(date, offset, "yyyy-M-d") === `${year}-${month + 1}-${day}`;
      });
    }

    let primaryColors = isAfter(currentDate, today) || !currentSummary ? ["#ddd"] : ["#ff2400"];
    let secondaryColors = [];

    if (exerciseMinutes > 30 || currentWorkouts.some(w => ["HighIntensityIntervalTraining", "Swimming", "Yoga"].includes(w.summary.activityType))) {
      primaryColors = ["#77dd77"];
    }

    if (exerciseMinutes > 30) {
      secondaryColors.push("#77dd77");
    }

    if (currentWorkouts.some(w => w.summary.activityType === "HighIntensityIntervalTraining")) {
      secondaryColors.push("#ff2400");
    }

    if (currentWorkouts.some(w => w.summary.activityType === "Swimming")) {
      secondaryColors.push("#007FFF");
    }

    if (currentWorkouts.some(w => w.summary.activityType === "Yoga")) {
      secondaryColors.push("#9A8FBF");
    }

    return (
      <div className="mdhui-calendar-day-with-data-value">
        <DayTrackerSymbol primaryColors={primaryColors} secondaryColors={secondaryColors} />
        <div className="calendar-day">{day}</div>
      </div>
    );
  }

  useEffect(() => {
    initialize();
    MyDataHelps.on("applicationDidBecomeVisible", initialize);
    MyDataHelps.on("externalAccountSyncComplete", initialize);

    return () => {
      MyDataHelps.off("applicationDidBecomeVisible", initialize);
      MyDataHelps.off("externalAccountSyncComplete", initialize);
    }
  }, [props]);

  return (
    <div className={styles.PhysicalActivityCalendar}>
      <div className="calendar-legend">
        <span><FontAwesomeSvgIcon icon={faCircle} size="xs" color="#007FFF" /> Swimming {activity?.workouts?.filter(w => w.summary.activityType === "Swimming" && new Date(w.startDate).getMonth() === props.month).length > 0 && "(" + activity?.workouts?.filter(w => w.summary.activityType === "Swimming" && new Date(w.startDate).getMonth() === props.month).length + ")"}</span>
        &nbsp;&nbsp;&nbsp;
        <span><FontAwesomeSvgIcon icon={faCircle} size="xs" color="#ff2400" /> HIIT {activity?.workouts?.filter(w => w.summary.activityType === "HighIntensityIntervalTraining" && new Date(w.startDate).getMonth() === props.month).length > 0 && "(" + activity?.workouts?.filter(w => w.summary.activityType === "HighIntensityIntervalTraining" && new Date(w.startDate).getMonth() === props.month).length + ")"}</span>
        &nbsp;&nbsp;&nbsp;
        <span><FontAwesomeSvgIcon icon={faCircle} size="xs" color="#9A8FBF" /> Yoga {activity?.workouts?.filter(w => w.summary.activityType === "Yoga" && new Date(w.startDate).getMonth() === props.month).length > 0 && "(" + activity?.workouts?.filter(w => w.summary.activityType === "Yoga" && new Date(w.startDate).getMonth() === props.month).length + ")"}</span>
        &nbsp;&nbsp;&nbsp;
        <span><FontAwesomeSvgIcon icon={faCircle} size="xs" color="#77dd77" /> 30 min of exercise {activity?.summaries?.filter(s => s.appleExerciseTime >= 30 && new Date(s.startDate).getMonth() === props.month).length > 0 && "(" + activity?.summaries?.filter(s => s.appleExerciseTime >= 30 && new Date(s.startDate).getMonth() === props.month).length + ")"}</span>
      </div>
      {loading && <LoadingIndicator />}
      {!loading && <Calendar year={props.year} month={props.month} dayRenderer={renderDay} weekStartsOn={1}></Calendar>}
    </div>
  )
};

PhysicalActivityCalendar.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number
};

PhysicalActivityCalendar.defaultProps = {
  year: new Date().getFullYear(),
  month: new Date().getMonth()
};

export default PhysicalActivityCalendar;
