import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DateRangeNavigator, Layout, Card, CardTitle } from '@careevolution/mydatahelps-ui';

import styles from './Habits.module.scss';

import PhysicalActivityCalendar from '../PhysicalActivityCalendar/PhysicalActivityCalendar';
import SleepCalendar from '../SleepCalendar/SleepCalendar';

const Habits = () => {
  let year = new Date().getFullYear();
  let month = new Date().getMonth();
  let initialIntervalStart = new Date(year, month, 1, 0, 0, 0, 0);
  const [intervalStart, setIntervalStart] = useState(initialIntervalStart);

  function onIntervalChange(newStart, newEnd) {
    setIntervalStart(newStart);
  }

  return (
    <div className={styles.Habits}>
      <Layout>
        <Card>
          <DateRangeNavigator intervalType='Month' intervalStart={intervalStart} onIntervalChange={onIntervalChange} />
        </Card>
        <Card>
          <CardTitle title="Physical Activity"></CardTitle>
          <PhysicalActivityCalendar year={intervalStart.getFullYear()} month={intervalStart.getMonth()} />
        </Card>
        <Card>
          <CardTitle title="Sleep"></CardTitle>
          <SleepCalendar year={intervalStart.getFullYear()} month={intervalStart.getMonth()} />
        </Card>
      </Layout>
    </div>
  );
}

Habits.propTypes = {};

Habits.defaultProps = {};

export default Habits;
