import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MyDataHelps from "@careevolution/mydatahelps-js";
import { LoadingIndicator, Calendar, DayTrackerSymbol } from '@careevolution/mydatahelps-ui';
import { add, parseISO, format } from 'date-fns';
import { faCircle, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';

import styles from './SleepCalendar.module.scss';

const SleepCalendar = (props) => {
  const [loading, setLoading] = useState(false);
  const [dailySleep, setDailySleep] = useState({});
  const [granularSleep, setGranularSleep] = useState({});

  var monthStart = new Date(props.year, props.month, 1, 0, 0, 0, 0);
  var monthEnd = add(monthStart, { months: 1 });

  async function getDailySleep() {
    let results = [];
    let pageId = null;
    do {
      let parameters = {
        namespace: 'AppleHealth',
        type: 'Sleep Analysis',
        observedAfter: add(monthStart, { days: -3 }),
        observedBefore: add(monthEnd, { days: 1 }),
        dataSource: {
          sourceName: 'Oura'
        }
      };
      if (pageId) {
        parameters.pageID = pageId;
      }
      let response = await MyDataHelps.queryDeviceDataV2DailySleep(parameters);
      results.push(...response.sleepStageSummaries);
      pageId = response.nextPageID;
    } while (pageId);
    return results;
  }

  async function getGranularSleep() {
    let results = [];
    let pageId = null;
    do {
      let parameters = {
        namespace: 'AppleHealth',
        type: 'Sleep Analysis',
        observedAfter: add(monthStart, { days: -3 }),
        observedBefore: add(monthEnd, { days: 1 }),
        dataSource: {
          sourceName: 'Oura'
        }
      };
      if (pageId) {
        parameters.pageID = pageId;
      }
      let response = await MyDataHelps.queryDeviceDataV2(parameters);
      results.push(...response.deviceDataPoints);
      pageId = response.nextPageID;
    } while (pageId);
    return results;
  }

  function initialize() {
    setLoading(true);
    Promise.all([getDailySleep(), getGranularSleep()]).then((results) => {
      setLoading(false);
      setDailySleep(results[0].filter(sd => ["Asleep", "AsleepREM", "AsleepCore", "AsleepDeep"].includes(sd.value)));
      setGranularSleep(results[1].filter(sd => sd.dataSource.sourceName === "Oura" && ["Asleep", "AsleepREM", "AsleepCore", "AsleepDeep"].includes(sd.value)));
    });
  }

  function getColor(value) {
    var hue = (value * 120).toString(10);
    return ["hsl(", hue, ",100%,50%)"].join("");
  }

  function timeSpanToMinutes(timeSpan) {
    const parts = timeSpan.split(':');
    const hours = parseInt(parts[0], 10);
    const minutes = parseInt(parts[1], 10);
    const seconds = parseInt(parts[2], 10);

    return (hours * 60) + minutes + (seconds / 60);
  }

  function renderDay(year, month, day) {

    if (!day) return <div></div>;

    let currentDaily = [];
    if (dailySleep && dailySleep.length) {
      currentDaily = dailySleep.filter((s, i) => {
        return s.date.substring(0, 10) === `${year}-${(month + 1).toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      });
    }

    let primaryColors = ["#ddd"];
    let secondaryColors = [];

    let currentSleepMinutes = currentDaily.reduce((acc, s) => acc + timeSpanToMinutes(s.duration), 0);

    if (currentDaily.length) {
      let percent = (currentSleepMinutes - 300) / 180;
      if (percent < 0) {
        percent = 0;
      }
      else if (percent > 1) {
        percent = 1;
      }
      primaryColors = [getColor(percent)];
    }

    let currentGranular = [];
    if (granularSleep && granularSleep.length) {
      currentGranular = granularSleep.filter((s, i) => {
        let date = parseISO(s.startDate);

        if (parseInt(format(date, "H")) >= 18) {
          date = add(date, { days: 1 });
        }

        return format(date, "yyyy-M-d") === `${year}-${month + 1}-${day}`;
      });
    }

    if (currentGranular.length) {
      let firstStart = currentGranular[currentGranular.length - 1].startDate;
      let firstAsleepDay = parseInt(format(parseISO(firstStart), "d"));
      let firstAsleepHour = parseInt(format(parseISO(firstStart), "H"));

      if (firstAsleepDay !== day && firstAsleepHour < 23) {
        secondaryColors.push("#040406");
      }
    }

    return (
      <div className="mdhui-calendar-day-with-data-value">
        <DayTrackerSymbol primaryColors={primaryColors} secondaryColors={secondaryColors} />
        <div className="calendar-day">{day}</div>
      </div>
    );
  }

  useEffect(() => {
    initialize();
    MyDataHelps.on("applicationDidBecomeVisible", initialize);
    MyDataHelps.on("externalAccountSyncComplete", initialize);

    return () => {
      MyDataHelps.off("applicationDidBecomeVisible", initialize);
      MyDataHelps.off("externalAccountSyncComplete", initialize);
    }
  }, [props]);

  return (
    <div className={styles.SleepCalendar}>
      <div className="calendar-legend">
        <div>
          <FontAwesomeSvgIcon icon={faCircle} size="xs" color="#040406" /> Asleep before 11 PM
          <FontAwesomeSvgIcon icon={faRefresh} size="lg" style={{ float: 'right' }} color="hsl(141, 71%, 48%)" onClick={initialize} />
        </div>
        <span><FontAwesomeSvgIcon icon={faCircle} size="lg" color="#FF0000" /> {"<"} 5 hours of sleep</span>
        &nbsp;&nbsp;&nbsp;
        <span><FontAwesomeSvgIcon icon={faCircle} size="lg" color="#00FF00" /> {">"} 8 hours of sleep</span>
      </div>
      {loading && <LoadingIndicator />}
      {!loading && <Calendar year={props.year} month={props.month} dayRenderer={renderDay} weekStartsOn={1}></Calendar>}
    </div>
  )
};

SleepCalendar.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number
};

SleepCalendar.defaultProps = {
  year: new Date().getFullYear(),
  month: new Date().getMonth()
};

export default SleepCalendar;
